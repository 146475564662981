<template>
  <div class="page-padding--bottom container">
    <edit-modal></edit-modal>

    <div class="flex flex-wrap section-padding">
      <div class="flex-2-fiths flex-s-whole" style="min-width: 400px;">

          <div v-if="itemDup.requiresUpdate || itemDup.updateInProgress" class="flex" style="height: 42px;">
            <tooltip v-if="itemDup.requiresUpdate" text="The data behind these statistics have recently been updated.">
              <i class="icon-alert"></i>
            </tooltip>
            <refresh-button v-if="itemDup.requiresUpdate" class="margin-space-top-small" :id="itemDup.id"></refresh-button>
            <tooltip v-if="itemDup.updateInProgress" text="Your project statistics are currently updating.">
              <loader :width="37" :percentage="itemDup.percentage_complete"></loader>
            </tooltip>
          </div>

          <div class="flex">
            <h1 class="ellipsis heading--simple--no-margin no-margin--top">{{ itemDup.name }}</h1>
            <div class="margin-space-left-small margin-space-right">
            <edit-button
              title="Edit the name of your project"
              attribute="name"
              label="Edit Site Name"
              :password-required="false"
              :on-close="(newName) => { this.itemDup.name = newName }"
              :url="`/sites/${itemDup.id}`"
              resource="site"
              size="small"
            ></edit-button>
            </div>
          </div>

        <div class="no-margin--top">
          <ul class="ul-unstyled ul-margin">
            <li>
              <span>
                <strong>Country: </strong>
                <a v-if="itemDup.country" :href="itemDup.country.countryProfileUrl">{{ itemDup.country.name }}</a>
                <span v-else>N/A</span>
              </span>
            </li>
            <li>
              <span>
                <strong>Location:</strong>
                <geo-centroid :geojson="itemDup.location"></geo-centroid>
              </span>
            </li>
            <li v-if="itemDup.location.type === 'Polygon'">
              <span><strong>Size of site: </strong>{{ itemDup.area }} km<sup>2</sup></span>
            </li>
            <li>
              <span><strong>Date created: </strong>{{ itemDup.created }}</span>
            </li>
            <li>
              <span><strong>Last updated: </strong>{{ itemDup.dataLastUpdatedAt }}</span>
            </li>
            <li>
              <span><strong>Created by: </strong>{{ itemDup.createdBy }}</span>
            </li>
            <li>
              <div class="flex flex-v-center">
                <span><strong>Site Type: </strong>{{ itemDup.site_type }}</span>
                  <edit-button
                    title="Edit site type"
                    attribute="site_type_name"
                    label="Edit Site Type"
                    :options="this.options"
                    :password-required="false"
                    :on-close="(newName) => { this.itemDup.site_type = newName }"
                    :url="`/sites/${itemDup.id}`"
                    resource="site"
                    size="small"
                  ></edit-button>
              </div>
            </li>
            <li>
              <strong v-if="itemDup.portfolios.length > 0">Portfolios: </strong>
              <span v-for="portfolio in itemDup.portfolios">
                <span class="text-fake-anchor">
                  <a :href="portfolio.portfolioUrl">{{ portfolio.name }}, </a>
                </span>
              </span>
            </li>
          </ul>
        </div>
        <a href="#site-overview" class="button--underline">
          Create Report/GIS Data Download
          <i class="button--down__icon icon-chevron-down"></i>
        </a>
      </div>

      <div class="flex-3-fiths flex-s-whole">
        <mapbox-simple id="map--site" :geojson="itemDup.location"></mapbox-simple>
      </div>
    </div>

  </div>
</template>

<script>
  import axios from 'axios'
  import { mixinWebsockets } from '../../mixins/mixin-websockets.js'
  import FlashProvider from '../../utilities/flash-provider.js'

  import EditButton from '../buttons/EditButton.vue'
  import GeoCentroid from '../geo/GeoCentroid.vue'
  import Loader from '../loading/Loader';
  import MapboxSimple from '../map/MapboxSimple.vue'
  import ModalTrigger from '../modal/ModalTrigger.vue'
  import PageReports from '../pagination/PageReports.vue'
  import PageMyDownloads from '../pagination/PageMyDownloads'
  import PageWrapper from '../pagination/PageWrapper.vue'
  import RefreshButton from '../buttons/RefreshButton.vue'
  import ScrollToTrigger from '../scrollTo/ScrollToTrigger.vue'
  import Tooltip from '../tooltip/Tooltip.vue'
  import Tabs from '../tabs/Tabs.vue'
  import Tab from '../tabs/Tab.vue'
  import EditModal from '../modal/EditModal.vue'
  import EditField from '../form_fields/EditField.vue'

  export default {
    name: 'show-site',

    components: {
      EditButton,
      GeoCentroid,
      Loader,
      MapboxSimple,
      ModalTrigger,
      PageMyDownloads,
      PageReports,
      PageWrapper,
      RefreshButton,
      ScrollToTrigger,
      Tab,
      Tabs,
      Tooltip,
      EditModal,
      EditField
    },

    mixins: [mixinWebsockets],

    props: {
      site: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        itemDup: this.site,
        websocketChannel: 'Site',
        updateMessage: `Site data for ${this.site.name} is updating`,
        completeMessage: `Site data for ${this.site.name} is up to date`,
        errorMessage: 'There was an error updating this site, please try again.',
        deletionMessage: "This project has been deleted, you are being returned to your site page.",
      }
    },

    mounted() {
      this.subscribeToChannel()
    },

    computed: {
      countryNotApplicable() {
        return this.itemDup.country_name === 'Not applicable';
      },

      portfolioName() {
        return this.itemDup.portfolios.map(portfolio => portfolio.name).join(', ')
      },

      options() {
        return this.site.site_types.map(site_type => { return { name: site_type.name, id: site_type.name}} );
      }
    },

    methods: {
      criticalClass(row) {
        return !row.critical ? ' icon--redlist-grey' : ''
      }
    }
  }

</script>

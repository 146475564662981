<template>
  <div class="map-wrapper full-height">
    <div :id="id" class="map full-height"></div>
  </div>
</template>

<script>
import { eventHub } from '../../ibat.js';
import * as turf from '@turf/turf';

import { mixinResponsive } from '../../mixins/mixin-responsive.js';
import { mixinMapbox, mixinMapboxInfo } from '../../mixins/mixin-mapbox.js';

export default {
  name: 'mapbox-countries',

  mixins: [mixinMapbox, mixinMapboxInfo, mixinResponsive],

  props: {
    id: {
      type: String,
      required: true
    },
    geojson: {
      type: Object,
      required: true
    },
    datasets: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      mapboxControls: []
    };
  },

  computed: {
    firstSymbolId() {
      const layers = this.map.getStyle().layers;
      // Find the index of the first symbol layer in the map style
      let firstSymbolId;
      for (let i = 0; i < layers.length; i++) {
        if (layers[i].type === 'symbol') {
          firstSymbolId = layers[i].id;
          break;
        }
      }
      return firstSymbolId;
    }
  },

  methods: {
    resizeMap() {
      setTimeout(() => {
        this.map.resize();
      }, 100);
    },
    getLayerConfig(id, tiles, layer) {
      return {
        id: id,
        type: 'fill',
        source: {
          type: 'vector',
          tiles: tiles
        },
        'source-layer': 'layer0',
        paint: {
          'fill-color': layer.color,
          'fill-opacity': 0.6
        },
        filter: layer.filter
      };
    }
  },

  mounted() {
    eventHub.$on('resizeMap', this.resizeMap);

    // This should probably be loaded from the back-end
    const datasetLookup = {
      wdpa: [
        {
          name: 'marine-protected-areas',
          filter:   ['all',
                      ['==', '$type', 'Polygon'],
                      ['in', 'MARINE', '1', '2']
                    ]
                  ,
          color: '#389cd7'
        },
        {
          name: 'terrestrial-protected-areas',
          filter: ['all',
                    ['==', '$type', 'Polygon'],
                    ['==', 'MARINE', '0']
                  ],
          color: '#64bf78'
        }
      ],
      kba: [
        {
          name: 'key-biodiversity-areas',
          filter: ['==', '$type', 'Polygon'],
          color: '#d16795'
        }
      ]
    };

    this.map.on('load', () => {
      this.datasets.forEach(dataset => {
        datasetLookup[dataset.slug].forEach(layer => {
          if (dataset.service_url) {
            this.map.addLayer(
              this.getLayerConfig(
                `${dataset.slug}-${layer.name}`,
                [dataset.service_url],
                layer
              ),
              this.firstSymbolId
            );
          }
        });
      });

      const bbox = turf.bbox(this.geojson);
      this.map.fitBounds(bbox, { animate: false });
    });
  }
};
</script>

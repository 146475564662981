<template>
  <div style="margin-top: 34px; margin-bottom: 30px;">
    <div class="flex flex-h-between desktop-only">
      <span class="list-item--heading-flex-1"></span>
      <span class="list-item--heading-flex-1 list-item--desktop-only">ID</span>
      <span class="list-item--heading-flex-4">Report Name</span>
      <span class="list-item--heading-flex-2 list-item--desktop-only">Type</span>
      <span class="list-item--heading-flex-3 list-item--desktop-only">
        <div class="flex">
          <tooltip
            class="tooltip"
            :text="countryTooltipText">
            <span class="column-title">Country</span>
          </tooltip>
        </div>
      </span>
      <span v-if="user.businessOwnerOrAdmin" class="list-item--heading-flex-3 list-item--desktop-only">Created By</span>
      <span class="list-item--heading-flex-2 list-item--desktop-only">Created<page-sort sort-key="created_at"></page-sort></span>
      <span class="list-item--heading-flex-1 list-item--desktop-only"></span>
    </div>

    <page-item-report v-show="!noResults"
      v-for="(item, index) in items"
      :key="generateKey(index)"
      :item="item"
      :can-delete="canDelete">
    </page-item-report>

  </div>
</template>

<script>
  import { eventHub } from '../../ibat.js'
  import PageItemReport from './PageItemReport.vue';
  import PageSort from './PageSort.vue';
  import Tooltip from '../tooltip/Tooltip.vue';
  import { TooltipTexts } from '../../mixins/mixin-tooltip-texts.js';
  import { mixinPaginationGroup } from '../../mixins/mixin-pagination-group.js'
  import { mixinLoggedIn } from '../../mixins/mixin-logged-in.js'
  import helpers from '../../utilities/helpers.js'

  export default {
    name: 'page-reports',

    components: { PageItemReport, PageSort, Tooltip },

    mixins: [ mixinPaginationGroup, TooltipTexts, mixinLoggedIn ],

    methods: {
      generateKey(string) {
        return helpers.generateKey(string)
      }
    }
  }
</script>

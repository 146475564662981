// IE 11 polyfillb
import { polyfill as objectAssign } from 'es6-object-assign'
objectAssign()

// libraries
import Vue from 'vue/dist/vue.esm'
import Vue2TouchEvents from 'vue2-touch-events'
import "core-js/shim"; // included < Stage 4 proposals
import "regenerator-runtime/runtime";

Vue.config.productionTip = false

Vue.use(Vue2TouchEvents)

// store
import store from './store/store.js'

// i18n
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)


import axios from './utilities/axios.js'
Vue.prototype.$http = axios

// vue components
import Accordion from './components/accordion/Accordion'
import AccordionItem from './components/accordion/AccordionItem'
import ApiCredentials from './components/api/ApiCredentials'
import Carousel from './components/carousel/Carousel'
import CarouselSlide from './components/carousel/CarouselSlide'
import ClearButton from './components/buttons/ClearButton'
import Clickable from './components/clickable/Clickable'
import CloseTab from './components/close/CloseTab'
import CollapseArticle from './components/collapse/CollapseArticle'
import CollapseArticleNoTransition from './components/collapse/CollapseArticleNoTransition'
import CookiePopup from './components/cookie_popup/CookiePopup'
import Dashboard from './components/dashboard/Dashboard'
import DropdownTarget from './components/dropdown/DropdownTarget'
import DropdownTrigger from './components/dropdown/DropdownTrigger'
import EditButton from './components/buttons/EditButton'
import EditField from './components/form_fields/EditField'
import EditModal from './components/modal/EditModal'
import FilterPane from './components/filters/FilterPane'
import FilterPaneTrigger from './components/filters/FilterPaneTrigger'
import FilterPaneWrapper from './components/filters/FilterPaneWrapper'
import FlashMessage from './components/notifications/FlashMessage'
import FormAddSectors from './components/form/FormAddSectors'
import FormCreateAccount from './components/form/FormCreateAccount'
import FormCreateDataDownload from './components/form/FormCreateDataDownload'
import FormCreatePortfolio from './components/form/FormCreatePortfolio'
import FormPortfolioSites from './components/form/FormPortfolioSites'
import FormAddToPortfolio from './components/form/FormAddToPortfolio'
import FormUserMigrations from './components/form/FormUserMigrations'
import FormMigrateUserData from './components/form/FormMigrateUserData'
import FormAddSiteType from './components/form/FormAddSiteType'
import FormCreateSubSector from './components/sectors/FormCreateSubSector'
import GeoCentroid from './components/geo/GeoCentroid'
import I18n from './components/translator/I18n'
import Mapbox from './components/map/Mapbox'
import MapboxCountries from './components/map/MapboxCountries'
import MapboxDataDownload from './components/map/MapboxDataDownload'
import MapboxSimple from './components/map/MapboxSimple'
import Modal from './components/modal/Modal'
import ModalTrigger from './components/modal/ModalTrigger'
import NavBurger from './components/nav/NavBurger'
import NavLink from './components/nav/NavLink'
import SiteOverview from './components/sites/SiteOverview'
import PageCountryProfiles from './components/pagination/PageCountryProfiles'
import PageMyDownloads from './components/pagination/PageMyDownloads'
import PageReports from './components/pagination/PageReports'
import PageSites from './components/pagination/PageSites'
import PageTabs from './components/tabs/PageTabs'
import PageWrapper from './components/pagination/PageWrapper'
import PdfPieChart from './components/charts/PdfPieChart'
import ScrollToTrigger from './components/scrollTo/ScrollToTrigger'
import SdgIcon from './components/icons/SdgIcon'
import ShowHide from './components/show_hide/ShowHide'
import ShowSite from './components/sites/ShowSite'
import ShowPortfolio from './components/portfolios/ShowPortfolio'
import Sites from './components/sites/Sites'
import StripePayment from './components/stripe/StripePayment'
import ShowBusiness from './components/business/ShowBusiness'
import Tab from './components/tabs/Tab'
import Tabs from './components/tabs/Tabs'
import TextInput from './components/form_fields/TextInput'
import Toggle from './components/basic/Toggle'
import Tooltip from './components/tooltip/Tooltip'
import TwoFactorAuth from './components/auth/TwoFactorAuth'
import UsageChart from './components/dashboard/UsageChart'
import User from './components/user/User'
import UserRegistrationForm from './components/user/UserRegistrationForm'
import VErrors from './components/errors/VErrors'
import DeleteOrganisationModal from './components/modal/DeleteOrganisationModal'
import DeleteUserModal from './components/modal/DeleteUserModal'
import SortingData from './components/sorting/SortingData'
import SubscriptionPlans from './components/subscription_plans/SubscriptionPlans'
import SubscriptionPlansSlide from './components/subscription_plans/SubscriptionPlansSlide'
import ContactUsFields from './components/contact_us/ContactUsFields.vue'

// create event hub and export so that it can be imported into .vue files
export const eventHub = new Vue()

document.addEventListener('turbo:load', (e) => {
  // This deals with Vue and Turbo trampling over one another. If the body is ever changed,
  // that indicates Vue should be reloaded. Turbo frame changes don't affect body. Checking
  // window.vue_app also copes with a full page reload.
  if (window.vue_app && document.body.dataset.vueLoaded)
    return

  document.body.dataset.vueLoaded = true

  window.vue_app = new Vue({
    el: '#v_app',
    store,
    i18n: new VueI18n({ silentTranslationWarn: true }),
    components: {
      Accordion,
      AccordionItem,
      ApiCredentials,
      Carousel,
      CarouselSlide,
      ClearButton,
      Clickable,
      CloseTab,
      CollapseArticle,
      CollapseArticleNoTransition,
      CookiePopup,
      Dashboard,
      DropdownTarget,
      DropdownTrigger,
      EditButton,
      EditField,
      EditModal,
      FilterPane,
      FilterPaneTrigger,
      FilterPaneWrapper,
      FlashMessage,
      FormAddSectors,
      FormCreateAccount,
      FormCreateDataDownload,
      FormCreatePortfolio,
      FormPortfolioSites,
      FormAddToPortfolio,
      FormUserMigrations,
      FormMigrateUserData,
      FormCreateSubSector,
      FormAddSiteType,
      GeoCentroid,
      I18n,
      Mapbox,
      MapboxCountries,
      MapboxDataDownload,
      MapboxSimple,
      Modal,
      ModalTrigger,
      NavBurger,
      NavLink,
      SiteOverview,
      PageCountryProfiles,
      PageMyDownloads,
      PageReports,
      PageSites,
      PageTabs,
      PageWrapper,
      PdfPieChart,
      ScrollToTrigger,
      SdgIcon,
      ShowHide,
      ShowSite,
      ShowPortfolio,
      Sites,
      StripePayment,
      ShowBusiness,
      Tab,
      Tabs,
      TextInput,
      Toggle,
      Tooltip,
      TwoFactorAuth,
      UsageChart,
      User,
      UserRegistrationForm,
      VErrors,
      DeleteOrganisationModal,
      DeleteUserModal,
      SortingData,
      SubscriptionPlans,
      SubscriptionPlansSlide,
      ContactUsFields
    },
    data: {
      product: {
        name: 'Single Report',
        description: 'Single report download for IBAT',
        amount: 75000, // 750 in cent
        currency: 'usd'
      },
      options: {
        zipCode: false
      }
    }
  })
})

export const storeCarto = {
  namespaced: true,

  state: {
    cartocss: {
      'opacity': '0.6',
      'line-width': '0.1',
      'polygon-comp-op': 'dst-over',
    },
    kba: {
      'line-color': '#40541b',
      'polygon-fill': '#FF0000'
    },
    kba_aze: {
      'line-color': '#40541b',
      'polygon-fill': '#0000FF'
    },
    wdpa_national: {
      'line-color': '#40541b',
      'polygon-fill': '#229A00'
    },
    wdpa_international: {
      'line-color': '#40541b',
      'polygon-fill': '#FF0000'
    },
    wdpa_regional: {
      'line-color': '#40541b',
      'polygon-fill': '#00FF00'
    }
  }
}
